<template>
    <b-card title="Cancel Order">
        <b-form>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Search By Order No">
                        <v-select :filterable="false" v-model="searchBoxForOrderNo" placeholder="Enter Order No"
                            :options="customersOrderNo" @search="onInputChangeOnInvoice" @input="onSelectedOrderNo">
                            <template #option="{ orderId, orderNo, customerName, customerId, customerMobileNo }">
                                <h4># {{ orderNo }}</h4>
                                <span>{{ customerName }} {{ '['+customerMobileNo + ']' }} </span>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="8">
                    <h4>Order Information</h4>
                    <b-row>
                        <b-col col="6">
                            <b-list-group>
                                <b-list-group-item><b>Order No</b></b-list-group-item>
                                <b-list-group-item><b>Name</b></b-list-group-item>
                                <b-list-group-item><b>Mobile No</b></b-list-group-item>
                                <b-list-group-item><b>Booking Date</b></b-list-group-item>
                                <b-list-group-item><b>Net Amount</b></b-list-group-item>
                            </b-list-group>
                        </b-col>

                        <b-col col="6">
                            <b-list-group>
                                <b-list-group-item><b># {{ orderNo }}</b></b-list-group-item>
                                <b-list-group-item>{{ customerName }}</b-list-group-item>
                                <b-list-group-item>{{ customerMobileNo}} <span v-if="address">{{ ' ('+address+')' }}</span></b-list-group-item>
                                <b-list-group-item>{{ bookingDate }} </b-list-group-item>
                                <b-list-group-item>{{ netAmount }} </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>

                </b-col>
                <b-col md="4" class="text-center">
                    <b-button variant="danger" @click="cancelBooking" style="margin-bottom:-240px;" class="mt-1 mr-1">Cancel This
                        Booking</b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BCol, BRow, BForm, BFormGroup, BFormInput, BButton, BListGroup, BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import api from '@/store/api'
import util from '@/store/utils'

export default {
    components: {
        BCard, BCol, BRow, BForm, BFormGroup, BFormInput, BButton, BListGroup, BListGroupItem, vSelect
    },
    data() {
        return {
            searchBoxForOrderNo: '',
            customersOrderNo: [],
            orderId:0,
            orderNo:'',
            customerName:'',
            customerMobileNo:'',
            address:'',
            bookingDate:'',
            netAmount:''

        }
    },
    methods: {
        onSelectedOrderNo(option) {
            // this.searchBoxForOrderNo = option.item;
            this.orderId = option.orderId;
            this.searchBoxForOrderNo = option.orderNo;
            this.getOrderDetail(option.orderId);
        },
        convertTimeStampToDateLocal(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },

        getOrderDetail(orderId) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrdersDetailById/' + orderId, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.orderId = orderId;
                    self.orderNo = response.data.orderNo;

                    self.bookingDate = util.convertTimeStampToDateLocal(response.data.orderCreateDate);

                    
                    self.customerName = response.data.customerName;
                    self.customerMobileNo = response.data.customerMobileNo;
                    self.address = response.data.customerAddress;
                    self.netAmount = response.data.totalNetAmount;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        onInputChangeOnInvoice(orderNo) {
            if (orderNo === '' || orderNo === undefined) {
                return
            }
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrdersFilteredListByOrderNoContaining/' + orderNo, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.customersOrderNo = response.data;
                    } else {
                        self.customersOrderNo = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        cancelBooking(){
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('put', '/orders/cancelOrder/' + self.orderId, data))
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    if(response.data){
                        self.$swal({
                        title: '',
                        text: 'Order Deleted Successfully',
                        icon: 'Success',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    }else{
                        self.$swal({
                        title: '',
                        text: "Order Can'nt Delete Because It Is Under Process",
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-secondary',
                        },
                        buttonsStyling: false,
                    })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    created() {

    }
}
</script>